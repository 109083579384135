@import "my-variables";
@import "bootstrap";


//my own;
.top-pad {
    padding-top: 115px;
    padding-bottom: 65px;
}

.alertBox {
    text-align: center;
    padding: 15px;
    background: rgba(11, 97, 216, 0.67);
    border-radius: 10px;
    margin-bottom: 15px;

    h5,
    p {
        color: white;
    }

    a {
        color: darkblue;
        font-weight: bold;
    }

}

@media screen and (max-width: 440px) {
    .top-pad {
        padding-top: 110px;

    }
}

.top-pad h1 {
    margin-top: 5px;
    margin-bottom: 25px;
}

.x-pad {
    margin-bottom: 60px;
}

.x-margin {
    margin-top: 10px;
}

.bg-info {
    background-color: $brand-primary;
}

.key-info {
    font-weight: bolder;
}

.card-block ul ul {
    list-style-position: outside;
}

.card-block ul ul li {
    margin-left: 25px;
}

.btn {
    white-space: pre-wrap;
}

.hide {
    visibility: hidden;
}
